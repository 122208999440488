import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {AccountService} from './account.service';

@Injectable({
  providedIn: 'root'
})

export class User {
  id: number;
  apiToken: any;
  userGroup: any;
  name: any;
  email: any;
}

export class AuthService {
  baseUrl = environment.API_URL;
  Token = '';
  private loggedIn = new BehaviorSubject<boolean>(false);
  cachedRequest: Array<HttpRequest<any>> = [];

  constructor(private http: HttpClient, private user: User, private accountService: AccountService) {
  }

  public collectFailedRequest(request): void {
    this.cachedRequest.push(request);
  }
  public retryFailedRequests(): void {
    // retry the requests. this method can
    // be called after the token is refreshed
  }



  login(logindata: { email: string, password: string }) {
    return new Promise((resolve, reject) => {
      this.accountService.login(logindata).then(data => {
        this.user.apiToken = data['token'];
        this.user.userGroup = data['role'];
        this.user.id = data['id'];
        this.user.name = data['name'];
        this.user.email = data['email'];
        sessionStorage.setItem('Token', data['token']);
        sessionStorage.setItem('role', data['role']);
        sessionStorage.setItem('id', data['id']);
        sessionStorage.setItem('name', data['name']);
        sessionStorage.setItem('email', data['email']);
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getToken(): string {
    return sessionStorage.getItem('Token');
  }

  isLoggedInObs() {
    if (sessionStorage.getItem('Token')) {
      this.loggedIn.next(true);
    } else {
      this.loggedIn.next(false);
    }
    return this.loggedIn.asObservable();
  }

}
