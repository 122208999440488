import { Component, OnInit } from '@angular/core';
import {LocationDetailsService} from '../../services/location-details.service';

@Component({
  selector: 'app-localtion-detail',
  templateUrl: './localtion-detail.component.html',
  styleUrls: ['./localtion-detail.component.scss']
})
export class LocaltionDetailComponent implements OnInit {

  public localStore = [];

  constructor(public localStoreDetails: LocationDetailsService) {
  }

  ngOnInit() {
    this.getLocalStore();
  }

  getLocalStore() {
    this.localStoreDetails.getLocationDetails().subscribe(
      response => {
        this.localStore = response;
        // console.log(this.localStore);
      },
      error => {
        // console.log(error);
      }
    );
  }
}
