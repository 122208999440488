import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReviewandFeedbackService {
  httpHeaders = new HttpHeaders({'Content-Type': 'application/json'});


  constructor(private http: HttpClient) { }

    getFeedback(): Observable<any> {
        return this.http.get(environment.API_URL + 'api/feedback/',
          {headers: this.httpHeaders});
      }
}


