import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.scss']
})
export class ImageCardComponent implements OnInit {
  buttonImageInstagram: 'src/assets/images/instagram-icon.png';
  images = [1, 2, 3, 4, 5, 6, 7, 8].map(() => `https://picsum.photos/900/500?random&t=${Math.random()}`);

  cards = [
    {
      name: 'John Doe',
      description: 'The playroom is looking more like home for our babies everyday.',
      img: this.images[1],
    },
    {
      name: 'Steven Gerrard',
      description: 'The playroom is looking more like home for our babies everyday.',
      buttonText: 'Button',
      img: this.images[0],

    },
    {
      name: 'Ian Rush',
      description: 'The playroom is looking more like home for our babies everyday.Lorem ipsum dolor sit amet,' +
        ' consectetur adipiscing elit, ' +
        'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud' +
        ' exercitation ullamco laboris nisi ' +
        'ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum ' +
        'dolore eu fugiat nulla pariatur. ' +
        'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
      img: this.images[3],
    },
    {
      name: 'Amit Gupta',
      description: 'The playroom is looking more like home for our babies everyday.',
      img: this.images[4]
    },
    {
      name: 'Dipesh Basnet',
      description: 'The playroom is looking more like home for our babies everyday.',
      img: this.images[5]
    },
    {
      name: 'John  Doe',
      description: 'The playroom is looking more like home for our babies everyday.Lorem ipsum dolor sit ' +
        'amet, consectetur adipiscing elit, ' +
        'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, ' +
        'quis nostrud exercitation ullamco laboris ' +
        'nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate ' +
        'velit esse cillum dolore eu fugiat nulla pariatur.' +
        ' Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
      buttonText: 'Button',
      img: this.images[7]
    },
    {
      name: 'Pratik Khadka',
      description: 'The playroom is looking more like home for our babies everyday.Lorem' +
        ' ipsum dolor sit amet, consectetur adipiscing elit, ' +
        'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ' +
        'veniam, quis nostrud exercitation ullamco laboris nisi' +
        ' ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in' +
        ' voluptate velit esse cillum dolore eu fugiat nulla pariatur.' +
        ' Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
      img: this.images[5]
    },
    {
      name: 'Torchi Rokaya',
      description: 'The playroom is looking more like home for our babies everyday.',
      img: 'https://mdbootstrap.com/img/Photos/Horizontal/Nature/4-col/img%20(34).jpg'
    },
    {
      name: 'Unish Maharjan',
      description: 'The playroom is looking more like home for our babies everyday.',
      img: this.images[2]
    },
  ];
  slides: any = [[]];

  chunk(arr, chunkSize) {
    const R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  constructor() {
  }

  ngOnInit() {
    this.slides = this.chunk(this.cards, 3);
  }

}
