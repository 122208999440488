import {Component, OnInit} from '@angular/core';
import {NavbarService} from '../../services/navbar.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public location = '';
  role: any;
  name: any;

  constructor(
    public nav: NavbarService,
    private router: Router,
    private route: ActivatedRoute,
    private service: AuthService,
    private spinner: NgxSpinnerService
  ) {
    this.location = router.url;
    this.route.data.subscribe(
      data => {
        // console.log(data);
        this.role = data.data;
      }
    );
  }

  getRoles() {
    this.role = sessionStorage.getItem('role');
  }

  getName() {
    this.name = sessionStorage.getItem('name');
  }

  ngOnInit() {
    // console.log('working');
    this.route.data.subscribe(
      data => {
        // console.log(data);
        this.role = data.data;
      }
    );

  }

  displaySpinner() {
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 1000);
  }

  logout() {
    sessionStorage.clear();
    this.router.navigateByUrl('/');
  }

}
