import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(public auth: AuthService, private router: Router, private toast: ToastrService, private spinner:NgxSpinnerService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const token: string = this.auth.getToken();

    if (token) {
      request = request.clone({headers: request.headers.set('Authorization', 'Token ' + token)});
    }

    // console.log('Before making api call: ', request);
    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            // console.log('After API call success:', event);
          }
        }),
      catchError(response => {
        if (response instanceof HttpErrorResponse) {
          if (response.status === 401) {
            this.auth.collectFailedRequest(request);
            this.toast.error('Your session is invalid, please login again!', 'User Unauthorized!');
            sessionStorage.clear();
            this.router.navigate(['']);
          }
        }
        return throwError(response);
      })
    );
  }
}
