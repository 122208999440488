import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-full',
  templateUrl: './client-full.component.html',
  styleUrls: ['./client-full.component.scss']
})
export class ClientFullComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
