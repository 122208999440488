import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ComponentsModule} from './shared/components/components.module';
import {NavbarService} from './shared/services/navbar.service';
import {SidebarService} from './shared/services/sidebar.service';
import {SidebarModule} from 'ng-sidebar';
import {FooterService} from './shared/services/footer.service';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxSpinnerModule} from 'ngx-spinner';
import {RatingModule} from 'ng-starrating';
import {TokenInterceptorService} from './shared/interceptors/token-interceptor.service';
import {AuthService} from './shared/services/auth.service';
import {BsDropdownModule} from 'ngx-bootstrap';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {AgmCoreModule} from '@agm/core';
import {environment} from '../environments/environment';
import { PrivacyComponent } from './privacyandtoc/privacy/privacy.component';


@NgModule({
  declarations: [
    AppComponent,
    PrivacyComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    ComponentsModule,
    SidebarModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      newestOnTop: true,
    }),
    NgxSpinnerModule,
    RatingModule,
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_API_KEY
    }),
  ],
  providers: [NavbarService, SidebarService, FooterService, AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
