import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {NavbarComponent} from './navbar/navbar.component';
import {ImageDetailComponent} from './image-detail/image-detail.component';
import {ImageFrameComponent} from './image-frame/image-frame.component';
import {LocaltionDetailComponent} from './localtion-detail/localtion-detail.component';
import {ImageCardComponent} from './image-card/image-card.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {UnderConstructionComponent} from './under-construction/under-construction.component';


@NgModule({
  declarations: [NavbarComponent, ImageDetailComponent,
    ImageFrameComponent, LocaltionDetailComponent, ImageCardComponent,
    PageNotFoundComponent, UnderConstructionComponent],
  exports: [
    NavbarComponent,
    ImageDetailComponent,
    ImageFrameComponent,
    LocaltionDetailComponent,
    ImageCardComponent,
    UnderConstructionComponent,

  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule
  ]
})
export class ComponentsModule {
}
