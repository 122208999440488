import { Component, OnInit } from '@angular/core';
import {PrivacyService} from '../privacy.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  privacyHtmlContent: any;
  constructor(private Privacy: PrivacyService, private titleService: Title) {
  }

  ngOnInit() {
    this.getPrivacyDetails();
    this.titleService.setTitle( 'MyTilesArt | Privacy Policy' );
  }

  getPrivacyDetails() {
    this.Privacy.getPrivacy().subscribe(response => {
      this.privacyHtmlContent = response;
      // console.log(response);
    });
  }

}
