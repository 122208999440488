import {Component, OnDestroy, OnInit} from '@angular/core';
import {timer} from 'rxjs';
import {OrderPollingService} from '../../services/order-polling.service';
import * as FileSaver from 'file-saver';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line:variable-name
  image_url =
    'https://4.bp.blogspot.com/-0Wwqn009dkY/WlEXudEAgSI/AAAAAAAAbNc/QcYMXoTln7U94A8ZorzUxioMs9fjPrjKwCLcBGAs/s1600/Photographer.jpg';
  private checkTimer: any;

  constructor(private service: OrderPollingService, private  route: ActivatedRoute) {
  }

  ngOnInit() {

    this.route.data.subscribe(
      data => {
        // console.log('dd', data);
        const role = data.data;
        if (role === 'store_admin' || role === 'shipping_admin') {
          this.checkForOrder();
        } else {
          // console.log('');
        }
      }
    );

  }

  checkForOrder() {
    this.checkTimer = timer(0, 10000)
      .subscribe(
        () =>
          this.service.checkNewOrder()
            .subscribe(response => {
              if (response.length !== 0) {
                // console.log('working' + response);
                response.forEach((uid) => {
                  this.service.getORderPhotos(uid).subscribe(
                    data => {
                      this.fileSaver(data.url, data.file_name);
                    }
                  );
                });
              } else {
                // console.log('blank image');
              }
            })
      );
  }

  fileSaver(url, filename) {
    try {
      FileSaver.saveAs(url, filename);
    } catch (e) {
      console.log('filesaver not supported', e);
    }
  }

  arrayLength() {
    const a = [1, 2, 3, 4, 5, 6];
    const b = [];

    console.log(a.length, b.length);
  }

  ngOnDestroy(): void {
    // this.checkTimer.unsubscribe();
  }
}
