import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BlankComponent} from './blank/blank.component';
import {ClientFullComponent} from './client-full/client-full.component';
import {RouterModule} from '@angular/router';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {ComponentsModule} from '../components/components.module';
import {SidebarComponent} from './sidebar/sidebar.component';
import {SidebarModule} from 'ng-sidebar';
import {UserManagementComponent} from './user-management/user-management.component';
import {FullComponent} from './full/full.component';
import {NgxSpinnerModule} from 'ngx-spinner';


@NgModule({
  declarations: [BlankComponent, ClientFullComponent, FooterComponent, HeaderComponent,
    SidebarComponent,
    UserManagementComponent,
    FullComponent,
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    SidebarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    SidebarModule,
    NgxSpinnerModule
  ]
})
export class LayoutsModule {
}
