import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderPollingService {
  baseUrl = environment.API_URL;

  constructor(private http: HttpClient) {
  }

  checkNewOrder(): Observable<any> {
    return this.http.get(`${this.baseUrl}api/photos/check/`);
  }

  getORderPhotos(id): Observable<any> {
    return this.http.get(`${this.baseUrl}api/download/${id}`);
  }
}
