import { Component, OnInit } from '@angular/core';
import {ReviewandFeedbackService } from '../../../views/admin/_service/review-and-feedback.service';

@Component({
  selector: 'app-image-detail',
  templateUrl: './image-detail.component.html',
  styleUrls: ['./image-detail.component.scss']
})
export class ImageDetailComponent implements OnInit {
  imageDetails = [];

  constructor(private imageDetailServicxe: ReviewandFeedbackService) { }

  ngOnInit() {
    this.displayImageDetails();
  }

  displayImageDetails() {
    this.imageDetailServicxe.getFeedback().subscribe(
      response => {
        this.imageDetails = response;
        // console.log(this.imageDetails);
      },
      error => {
        // console.log(error);
      });
  }

}
