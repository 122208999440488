import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AccountService {
  constructor(private http: HttpClient) {
  }

  login(loginData) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.API_URL + 'api/login/', loginData).subscribe(
        response => resolve(response),
        error => {
          reject(error);
        }
      );
    });
  }

  logout() {
    return this.http.get(environment.API_URL + 'rest-auth/logout/');
  }

}
