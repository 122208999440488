import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrivacyService {

  constructor(private http: HttpClient) {
  }

  getPrivacy(): Observable<any> {
    return this.http.get(environment.API_URL + 'api/privacy/',
      {responseType: 'text'});
  }
}
