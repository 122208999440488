import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(public router: Router, public toast: ToastrService) {
    // console.log(role);
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const expectedRole = route.data.expectedRole;
    // console.log(route);
    const role = sessionStorage.getItem('role');

    if (role !== expectedRole) {
      this.router.navigateByUrl('/login');
      // console.log('access denied');
      this.toast.error('you are not authorized to access this content', 'Error');
      return false;
    }
    // console.log('access granted');
    return true;

  }

}
