import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {UserManagementComponent} from './shared/layouts/user-management/user-management.component';
import {NavbarItemsResolver} from './shared/_resolvers/navbar-items.resolver';
import {FullComponent} from './shared/layouts/full/full.component';
import {AuthGuard} from './shared/guards/auth.guard';
import {PageNotFoundComponent} from './shared/components/page-not-found/page-not-found.component';
import {BlankComponent} from './shared/layouts/blank/blank.component';
import {RoleGuard} from './shared/guards/role.guard';
import {UnderConstructionComponent} from './shared/components/under-construction/under-construction.component';
import { PrivacyComponent } from './privacyandtoc/privacy/privacy.component';


const routes: Routes = [
  {
    path: '',
    component: UserManagementComponent,
    resolve: {data: NavbarItemsResolver},
    loadChildren: () => import('./views/user-management/user-management.module').then(usersManagement =>
      usersManagement.UserManagementModule)
  },
  {
    path: 'page-not-found',
    component: BlankComponent,
    children: [
      {
        path: '', component: PageNotFoundComponent
      }
    ]
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: '',
    component: FullComponent,
    resolve: {data: NavbarItemsResolver},
    // canActivate: [AuthGuard],
    children: [
      {
        path: 'client',
        loadChildren: () => import('./views/client/client.module').then(client => client.ClientModule),
        canActivate: [RoleGuard],
        data: {
          expectedRole: 'user'
        }
      },
      {
        path: 'admin',
        loadChildren: () => import('./views/admin/admin.module').then(admin => admin.AdminModule),
        canActivate: [RoleGuard],
        data: {
          expectedRole: 'admin'
        }
      },
      {
        path: 'store-admin',
        loadChildren: () => import('./views/store-admin/store-admin.module').then(storeAdmin => storeAdmin.StoreAdminModule),
        canActivate: [RoleGuard],
        data: {
          expectedRole: 'store_admin'
        }
      },
      {
        path: 'shipping-admin',
        loadChildren: () => import('./views/shipping-admin/shipping-admin.module').then(shipAdmin => shipAdmin.ShippingAdminModule),
        canActivate: [RoleGuard],
        data: {
          expectedRole: 'shipping_admin'
        }
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/page-not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule],
  providers: [NavbarItemsResolver]
})
export class AppRoutingModule {
}
