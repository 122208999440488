import {Injectable} from '@angular/core';
import {Resolve, ActivatedRoute, RouterStateSnapshot, Router, ActivatedRouteSnapshot} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class NavbarItemsResolver implements Resolve<any> {
  user: any;
  navItems: {};
  role: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return sessionStorage.getItem('role');
    // console.log('role is ' + this.role);
  }

}
