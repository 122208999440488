import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  role: any;

  constructor() { }

  getRoles() {
   this.role =  sessionStorage.getItem('role');
  }

  ngOnInit() {
    this.getRoles();
  }

}
