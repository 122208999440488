import {Component, OnInit} from '@angular/core';
import {SidebarService} from '../../services/sidebar.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  role: any;
  user: string;
  public opened = false;

  constructor(private sidebar: SidebarService, private route: Router, private actRoute: ActivatedRoute) {
  }

  public _toggleSidebar() {
    this.opened = !this.opened;
  }

  ngOnInit() {
    this.user = sessionStorage.getItem('name');
    this.role = sessionStorage.getItem('role');

    this.opened = false;
  }

  logout() {
    if (confirm('are you sure you want to log out of the system?') === true) {
      sessionStorage.clear();
      this.route.navigateByUrl('/');
    }
  }
}
