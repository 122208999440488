import {Component} from '@angular/core';
import {NavbarService} from './shared/services/navbar.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {SidebarService} from './shared/services/sidebar.service';
import {FooterService} from './shared/services/footer.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Tiles-Art-frontend';
  private hideNavPaths = [
    '',
    '/login',
    '/reset-password',
    '/change-password',
    '/page-not-found'
  ];

  private hidesideNavPaths = [
    '',
    '/login',
    '/reset-password',
    '/change-password',
    '/page-not-found',
    '/local-stores',
    '/page-not-found',
    '/faq',
    '/support',
  ];

  private hideFooterPaths = [
    '/support',
    '',
    '/local-stores',
    '/reset-password',
    '/change-password',
    '/page-not-found',
    '/faq',
    '/support',
    '/admin/dashboard',
    '/admin/about',
    '/admin/users',
    '/admin/terms',
    '/admin',
    '/login'

  ];

  constructor(private router: Router,
              public nav: NavbarService,
              public sidenav: SidebarService,
              public footer: FooterService,
              private location: Location) {

    this.router.events.subscribe(() => {
      if (this.hideNavPaths.includes(this.location.path())) {
        this.nav.hide();
      }
    });

    this.router.events.subscribe(() => {
      if (this.hidesideNavPaths.includes(this.location.path())) {
        this.sidenav.hide();
      }
    });
    this.router.events.subscribe(() => {
      if (this.hideFooterPaths.includes(this.location.path())) {
        this.footer.hide();
      }
    });
  }
}
